
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { getAuthMedia } from "@/utils/File";
import UserMixin from "@/mixins/UserMixin";
import { Options } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { namespace } from "s-vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import DeleteAccountModal from "@/components/Modals/DeleteAccountModal.vue";
import { remove } from "@/utils/localForageUtil";

const StudentModule = namespace("student");

@Options({
  components: { DefaultLayout, DeleteAccountModal },
})
export default class Profile extends mixins(UserMixin) {
  public name = "Profile";

  private profileSrc = "";
  private file: File | null = null;

  @StudentModule.Action("uploadAvatar/upload")
  public uploadStudentAvatar!: (options: ICrudOptions) => Promise<void>;

  @StudentModule.Getter("uploadAvatar/getIsLoading")
  public uploadStudentAvatarLoading!: boolean;

  public mounted() {
    getAuthMedia({
      method: "get",
      url: "students/current/avatar",
    }).then((blob: any) => {
      this.profileSrc = blob;
    });
  }

  private onFileChange() {
    const file = (this.$refs.fileRef as HTMLFormElement).files[0];
    this.profileSrc = URL.createObjectURL(file);
    this.file = file;
  }

  private onUpload() {
    (this.$refs.fileRef as HTMLFormElement).click();
  }

  private async onSubmit(): Promise<void> {
    if (this.file && this.currentUser) {
      const formData = new FormData();
      formData.append("file", this.file);

      const profileUrl = `students/avatar?studentId=${this.currentUser.id}`;

      await this.uploadStudentAvatar({
        resource: profileUrl,
        descriptionField: "",
        data: formData,
      });

      await remove("students/current/avatar");
    }
  }

  private onDeleteStudent() {
    (this.$refs.deleteAccountModal as DeleteAccountModal).onDeleteStudent();
  }
}
